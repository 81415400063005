export default {
  ALL: '/all',
  CREATE: '/create',
  V1: '/v1',

  // auth
  LOGIN: '/session',
  REQUEST_PASSWORD_RESET: '/password_reset_request',
  SUBMIT_PASSWORD_RESET: '/password_reset',

  // advertising
  ADVERTISING: '/advertising',
  PROVIDER: '/provider',

  BLACKLISTS: '/card_blacklists',
  MCC: '/mcc',
  MERCHANT: '/merchant',

  // campaigns
  CAMPAIGNS: '/campaigns',

  // cards
  CARDS: '/cards',
  CARD_TRANSACTIONS: '/card_transactions',
  CHANGE_STATUS: '/change_status',
  REASSOCIATE: '/reassociate',

  // check ins
  CHECK_INS: '/check_ins',

  // corporate partners
  CORPORATE_COUPONS: '/corporate_coupons',
  CORPORATE_PARTNER: '/corporate_partner',
  CSV_UPLOAD: '/csv_upload',
  CSV_UPLOADS: '/csv_uploads',

  // credit balance adjustments
  CREDIT_BALANCE_ADJUSTMENTS: '/credit_balance_adjustments',
  ADJUST_CREDITS: '/adjust_credits',

  // credit bands
  BANDS: '/bands',

  GIFTS: '/gifts',
  NOTIFICATIONS: '/notifications',

  // credit offsets
  OFFSETS: '/offsets',

  // qualifiers
  PROVIDER_QUALIFIERS: '/providerqualifiers',
  UNIFIED_QUALIFIERS: '/qualifiers',
  QUALIFIER: '/qualifier',

  // theater chains
  THEATER_CHAIN: '/theater_chain',
  THEATER_CHAINS: '/theater_chains',

  // theaters
  THEATERS: '/theaters',
  THEATERS_FILE: '/theaters_file',

  // ticket types
  TICKET_TYPE: '/ticket_type',
  TICKET_TYPES: '/ticket_types',
  TICKET_TYPES_BLACK: '/ticketTypes',

  // movies
  MOVIE_OVERRIDE: '/movie_override',
  MOVIE: '/movie',
  MOVIES: '/movies',
  MOVIES_FILE: '/movies_file',
  MOVIES_POSTER: '/upload_poster',
  FEATURED: '/featured',
  STATUS: '/status',

  // networks
  NETWORKS: '/networks',
  NETWORK: '/network',

  // postal codes
  POSTAL_CODES: '/postal_codes',

  // reservations
  RESERVATIONS: '/reservations',

  SERVICE_FEE: '/serviceFee',

  VIP_USER: '/vip_user',
  VIP_USERS: '/vip_users',

  // brands
  BRANDS: '/brands',

  // credits
  CREDITS: '/credits',

  // subscriptions
  SUBSCRIPTIONS: '/subscriptions',

  // users
  USERS: '/users',
  OFFER_FOR_ISSUE: '/offers_for_issue',
  RESET_PASSWORD: '/user_password_resets',
  CANCEL: '/cancel',
  BACKDATE: '/backdate_cancellation',

  // waitlist_users
  WAITLIST_USERS: '/waitlist_users',
  WAITLIST_USER_INVITATIONS: '/waitlist_user_invitations',

  // offers
  OFFERS: '/offers',
  ADD_OFFER_REWARDS: '/offer_rewards',

  // offer walls
  OFFER_WALLS: '/offer_walls',
  OFFER_WALL_ELEMENTS: '/offer_wall_elements',
  REFRESH_CATEGORIES: 'refresh_cats',
  OFFER_WALL_SECTION: '/offer_wall_section',

  // preshows
  PRESHOWS: '/preshows',

  // prices
  PRICES: '/prices',

  // admin users
  ADMIN_USERS: '/admin_users',
  CHANGE_PASSWORD: '/change_password',
  ACTIVATE: '/activate',
  DEACTIVATE: '/deactivate',

  // rewards
  OFFER_REWARDS: '/offer_rewards',

  // spots
  SPOTS: '/spots',

  // surveys
  SURVEYS: '/surveys',

  // spot actions
  SPOT_ACTIONS: '/spot_actions',

  // items
  ITEMS: '/items'
};
