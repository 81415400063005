// libs
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import localForage from 'localforage';

// action
import { Types } from 'src/redux/actions/authAction';

// constant
import { PERSIST_KEY } from 'src/constants/routers';

// reducers
import adminUsers from './adminUsersReducer';
import advertisings from './advertisingsReducer';
import auth from './authReducer';
import blacklists from './blacklistsReducer';
import brands from './brandsReducer';
import campaigns from './campaignsReducer';
import cards from './cardsReducer';
import cardTransactions from './cardTransactionsReducer';
import checkIns from './checkInsReducer';
import corporatePartners from './corporatePartnersReducer';
import creditBands from './creditBandsReducer';
import creditOffsets from './creditOffsetsReducer';
import gifts from './giftsReducer';
import movies from './moviesReducer';
import networks from './networksReducer';
import offers from './offersReducer';
import offerWalls from './offerWallsReducer';
import postalCodes from './postalCodesReducer';
import preshows from './preshowsReducer';
import prices from './pricesReducer';
import qualifiers from './qualifiersReducer';
import reservations from './reservationsReducer';
import rewards from './rewardsReducer';
import serviceFeeOffsets from './serviceFeeOffsetsReducer';
import spots from './spotsReducer';
import users from './usersReducer';
import waitlistUsers from './waitlistUsersReducer';
import surveys from './surveysReducer';
import spotActions from './spotActionsReducer';
import theaterChains from './theaterChainsReducer';
import theaters from './theatersReducer';
import ticketTypes from './ticketTypesReducer';
import items from './itemsReducer';
import vipUsers from './vipUsersReducer';

const usersPersistConfig = {
  key: 'users',
  storage: localForage,
  blacklist: ['userDetails']
};

const theaterChainsPersistConfig = {
  key: 'theaterChains',
  storage: localForage
};

const ticketTypesPersistConfig = {
  key: 'ticketTypes',
  storage: localForage
};

const appReducer = combineReducers({
  adminUsersReducer: adminUsers,
  advertisingsReducer: advertisings,
  authReducer: auth,
  blacklistsReducer: blacklists,
  brandsReducer: brands,
  campaignsReducer: campaigns,
  cardsReducer: cards,
  cardTransactionsReducer: cardTransactions,
  checkInsReducer: checkIns,
  corporatePartnersReducer: corporatePartners,
  creditBandsReducer: creditBands,
  creditOffsetsReducer: creditOffsets,
  giftsReducer: gifts,
  moviesReducer: movies,
  networksReducer: networks,
  offersReducer: offers,
  offerWallsReducer: offerWalls,
  postalCodesReducer: postalCodes,
  preshowsReducer: preshows,
  pricesReducer: prices,
  qualifiersReducer: qualifiers,
  reservationsReducer: reservations,
  rewardsReducer: rewards,
  serviceFeeOffsetsReducer: serviceFeeOffsets,
  spotsReducer: spots,
  surveysReducer: surveys,
  spotActionsReducer: spotActions,
  itemsReducer: items,
  theaterChainsReducer: persistReducer(theaterChainsPersistConfig, theaterChains),
  theatersReducer: theaters,
  ticketTypesReducer: persistReducer(ticketTypesPersistConfig, ticketTypes),
  usersReducer: persistReducer(usersPersistConfig, users),
  vipUsersReducer: vipUsers,
  waitlistUsersReducer: waitlistUsers,
  // add more reducer to combine
});

const rootReducer = (state, action) => {
  if (action.type === Types.LOGOUT_REQUEST) {
    // reset the state of a Redux store
    localForage.removeItem(`persist:${PERSIST_KEY}`);
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
