import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardContainer from 'src/containers/DashboardContainer';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/RoutesGuard/AuthGuard';
import GuestGuard from 'src/components/RoutesGuard/GuestGuard';
import { ROUTES } from 'src/constants/routers';

const LoginPage = lazy(() => import('src/containers/auth/LoginContainer'));
const RequestPasswordResetPage = lazy(() =>
  import('src/containers/auth/RequestPasswordResetContainer')
);
const SubmitPasswordResetPage = lazy(() =>
  import('src/containers/auth/SubmitPasswordResetContainer')
);
const AdvertisingsLandingPage = lazy(() =>
  import('src/containers/advertisings/AdvertisingsLandingContainer')
);
const AdvertisingCreatePage = lazy(() =>
  import('src/containers/advertisings/AdvertisingCreateContainer')
);
const AdvertisingDetailsPage = lazy(() =>
  import('src/containers/advertisings/AdvertisingDetailsContainer')
);
const AdvertisingProviderCreatePage = lazy(() =>
  import('src/containers/advertisings/AdvertisingProviderCreateContainer')
);
const AdvertisingProviderDetailsPage = lazy(() =>
  import('src/containers/advertisings/AdvertisingProviderDetailsContainer')
);
const BlacklistsLandingPage = lazy(() =>
  import('src/containers/blacklists/BlacklistsLandingContainer')
);
const BlacklistsMCCDetailsPage = lazy(() =>
  import('src/containers/blacklists/BlacklistMCCDetailsContainer')
);
const BlacklistMCCNewPage = lazy(() =>
  import('src/containers/blacklists/BlacklistMCCNewContainer')
);
const BlacklistMerchantDetailsPage = lazy(() =>
  import('src/containers/blacklists/BlacklistMerchantDetailsContainer')
);
const BlacklistMerchantNewPage = lazy(() =>
  import('src/containers/blacklists/BlacklistMerchantNewContainer')
);
const CampaignsLandingPage = lazy(() =>
  import('src/containers/campaigns/CampaignsLandingContainer')
);
const CampaignDetailsPage = lazy(() =>
  import('src/containers/campaigns/CampaignDetailsContainer')
);
const CampaignCreatePage = lazy(() =>
  import('src/containers/campaigns/CampaignCreateContainer')
);
const CardsLandingPage = lazy(() =>
  import('src/containers/cards/CardsLandingContainer')
);
const CardDetailsPage = lazy(() =>
  import('src/containers/cards/CardDetailsContainer')
);
const CardTransactionDetailsPage = lazy(() =>
  import('src/containers/cardTransactions/CardTransactionDetailsContainer')
);
const CheckInDetailsPage = lazy(() =>
  import('src/containers/checkIns/CheckInDetailsContainer')
);
const CorporatePartnersLandingPage = lazy(() =>
  import('src/containers/corporatePartners/CorporatePartnersLandingContainer')
);
const CorporatePartnerDetailsPage = lazy(() =>
  import('src/containers/corporatePartners/CorporatePartnerDetailsContainer')
);
const CorporatePartnerCreatePage = lazy(() =>
  import('src/containers/corporatePartners/CorporatePartnerCreateContainer')
);
const CreditBandDetailsPage = lazy(() =>
  import('src/containers/creditBands/CreditBandDetailsContainer')
);
const CreditOffsetsLandingPage = lazy(() =>
  import('src/containers/creditOffsets/CreditOffsetsLandingContainer')
);
const CreditOffsetDetailsPage = lazy(() =>
  import('src/containers/creditOffsets/CreditOffsetDetailsContainer')
);
const GiftPurchasesLandingPage = lazy(() =>
  import('src/containers/giftPurchases/GiftPurchasesLandingContainer')
);
const GiftPurchaseDetailsPage = lazy(() =>
  import('src/containers/giftPurchases/GiftPurchaseDetailsContainer')
);
const CreditOffsetCreatePage = lazy(() =>
  import('src/containers/creditOffsets/CreditOffsetCreateContainer')
);
const ServiceFeeOffsetsLandingPage = lazy(() =>
  import('src/containers/serviceFeeOffsets/ServiceFeeOffsetsLandingContainer')
);
const ServiceFeeOffsetDetailsPage = lazy(() =>
  import('src/containers/serviceFeeOffsets/ServiceFeeOffsetDetailsContainer')
);
const ServiceFeeOffsetCreatePage = lazy(() =>
  import('src/containers/serviceFeeOffsets/ServiceFeeOffsetCreateContainer')
);
const UsersLandingPage = lazy(() =>
  import('src/containers/user/UserLandingContainer')
);
const UserDetailPage = lazy(() =>
  import('src/containers/user/UserDetailContainer')
);
const VipUsersLandingPage = lazy(() =>
  import('src/containers/vipUsers/VipUsersLandingContainer')
);
const VipUserCreatePage = lazy(() =>
  import('src/containers/vipUsers/VipUserCreateContainer')
);
const WaitlistUserAddPage = lazy(() =>
  import('src/containers/waitlistUsers/WaitlistUserAddContainer')
);
const WaitlistUserDetailsPage = lazy(() =>
  import('src/containers/waitlistUsers/WaitlistUserDetailsContainer')
);
const WaitlistUsersInvitePage = lazy(() =>
  import('src/containers/waitlistUsers/WaitlistUsersInviteContainer')
);
const WaitlistUsersLandingPage = lazy(() =>
  import('src/containers/waitlistUsers/WaitlistUsersLandingContainer')
);
const MoviesLandingPage = lazy(() =>
  import('src/containers/movies/MoviesLandingContainer')
);
const MovieDetailsPage = lazy(() =>
  import('src/containers/movies/MovieDetailsContainer')
);
const MoviesHomeListPage = lazy(() =>
  import('src/containers/movies/MoviesHomeListContainer')
);
const MoviesCarouselPage = lazy(() =>
  import('src/containers/movies/MoviesCarouselContainer')
);
const NetworksLandingPage = lazy(() =>
  import('src/containers/networks/NetworksLandingContainer')
);
const NetworkDetailsPage = lazy(() =>
  import('src/containers/networks/NetworkDetailsContainer')
);
const PostalCodesLandingPage = lazy(() =>
  import('src/containers/postalCodes/PostalCodesLandingContainer')
);
const PostalCodeDetailsPage = lazy(() =>
  import('src/containers/postalCodes/PostalCodeDetailsContainer')
);
const PricesLandingPage = lazy(() =>
  import('src/containers/prices/PricesLandingContainer')
);
const PriceDetailsPage = lazy(() =>
  import('src/containers/prices/PriceDetailsContainer')
);
const QualifiersLandingPage = lazy(() =>
  import('src/containers/qualifiers/QualifiersLandingContainer')
);
const UnifiedQualifierCreatePage = lazy(() =>
  import('src/containers/qualifiers/UnifiedQualifierCreateContainer')
);
const UnifiedQualifierDetailsPage = lazy(() =>
  import('src/containers/qualifiers/UnifiedQualifierDetailsContainer')
);
const ProviderQualifierCreatePage = lazy(() =>
  import('src/containers/qualifiers/ProviderQualifierCreateContainer')
);
const ProviderQualifierDetailsPage = lazy(() =>
  import('src/containers/qualifiers/ProviderQualifierDetailsContainer')
);
const ReservationDetailsPage = lazy(() =>
  import('src/containers/reservations/ReservationDetailsContainer')
);
const TheaterChainsLandingPage = lazy(() =>
  import('src/containers/theaterChains/TheaterChainsLandingContainer')
);
const TheaterChainDetailsPage = lazy(() =>
  import('src/containers/theaterChains/TheaterChainDetailsContainer')
);
const TheaterChainAddPage = lazy(() =>
  import('src/containers/theaterChains/TheaterChainAddContainer')
);
const TheatersLandingPage = lazy(() =>
  import('src/containers/theaters/TheatersLandingContainer')
);
const TheaterDetailsPage = lazy(() =>
  import('src/containers/theaters/TheaterDetailsContainer')
);
const TheaterCreatePage = lazy(() =>
  import('src/containers/theaters/TheaterCreateContainer')
);
const TicketTypesLandingPage = lazy(() =>
  import('src/containers/ticketTypes/TicketTypesLandingContainer')
);
const TicketTypeDetailsPage = lazy(() =>
  import('src/containers/ticketTypes/TicketTypeDetailsContainer')
);
const OffersLandingPage = lazy(() =>
  import('src/containers/offers/OffersLandingContainer')
);

const OfferDetailsPage = lazy(() =>
  import('src/containers/offers/OfferDetailsContainer')
);
const OfferWallsLandingPage = lazy(() =>
  import('src/containers/offerWalls/OfferWallsLandingContainer')
);
const OfferWallDetails = lazy(() =>
  import('src/containers/offerWalls/OfferWallDetailsContainer')
);
const PreshowsLandingPage = lazy(() =>
  import('src/containers/preshows/PreshowsLandingContainer')
);
const PreshowDetailPage = lazy(() =>
  import('src/containers/preshows/PreshowDetailsContainer')
);
const BrandsLandingPage = lazy(() =>
  import('src/containers/brands/BrandsLandingContainer')
);
const SpotsLandingPage = lazy(() =>
  import('src/containers/spots/SpotsLandingContainer')
);
const SpotDetailsPage = lazy(() =>
  import('src/containers/spots/SpotDetailsContainer')
);
const SpotActionsLandingPage = lazy(() =>
  import('src/containers/spotActions/SpotActionsLandingContainer')
);
const SurveysLandingPage = lazy(() =>
  import('src/containers/surveys/SurveysLandingContainer')
);
const SurveyDetailsPage = lazy(() =>
  import('src/containers/surveys/SurveyDetailsContainer')
);
const ItemsLandingPage = lazy(() =>
  import('src/containers/items/ItemsLandingContainer')
);
const ItemDetailsPage = lazy(() =>
  import('src/containers/items/ItemDetailsContainer')
);
const AdminUsersLandingPage = lazy(() =>
  import('src/containers/administrators/AdminUserLandingContainer')
);
const AdminUserDetailPage = lazy(() =>
  import('src/containers/administrators/AdminUserDetailContainer')
);
const NotFoundPage = lazy(() => import('src/pages/NotFoundPage'));

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={route}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: ROUTES.NOTFOUND,
    layout: MainLayout,
    component: NotFoundPage
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ROUTES.LOGIN,
    layout: MainLayout,
    component: LoginPage
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ROUTES.REQUEST_PASSWORD_RESET,
    layout: MainLayout,
    component: RequestPasswordResetPage
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ROUTES.SUBMIT_PASSWORD_RESET,
    layout: MainLayout,
    component: SubmitPasswordResetPage
  },
  {
    path: ROUTES.HOME,
    guard: AuthGuard,
    layout: DashboardContainer,
    routes: [
      {
        exact: true,
        path: ROUTES.USERS,
        component: UsersLandingPage
      },
      {
        exact: true,
        path: ROUTES.HOME,
        component: () => <Redirect to={ROUTES.USERS} />
      },
      {
        exact: true,
        path: `${ROUTES.ADVERTISINGS}`,
        component: AdvertisingsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.ADVERTISING_CREATE}`,
        component: AdvertisingCreatePage
      },
      {
        exact: true,
        path: `${ROUTES.ADVERTISINGS}/:id`,
        component: AdvertisingDetailsPage
      },
      {
        exact: true,
        path: `${ROUTES.ADVERTISING_PROVIDERS_CREATE}`,
        component: AdvertisingProviderCreatePage
      },
      {
        exact: true,
        path: `${ROUTES.ADVERTISING_PROVIDERS}/:id`,
        component: AdvertisingProviderDetailsPage
      },
      {
        exact: true,
        path: ROUTES.BLACKLISTS,
        component: BlacklistsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.BLACKLISTS}${ROUTES.MCC}/:id`,
        component: BlacklistsMCCDetailsPage
      },
      {
        exact: true,
        path: `${ROUTES.BLACKLISTS}${ROUTES.NEW_MCC}`,
        component: BlacklistMCCNewPage
      },
      {
        exact: true,
        path: `${ROUTES.BLACKLISTS}${ROUTES.MERCHANT}/:id`,
        component: BlacklistMerchantDetailsPage
      },
      {
        exact: true,
        path: `${ROUTES.BLACKLISTS}${ROUTES.NEW_MERCHANT}`,
        component: BlacklistMerchantNewPage
      },
      {
        exact: true,
        path: ROUTES.CAMPAIGNS,
        component: CampaignsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.CAMPAIGNS}/:id`,
        component: CampaignDetailsPage
      },
      {
        exact: true,
        path: ROUTES.CAMPAIGNS_CREATE,
        component: CampaignCreatePage
      },
      {
        exact: true,
        path: `${ROUTES.CARDS}`,
        component: CardsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.CARDS}/:id`,
        component: CardDetailsPage
      },
      {
        exact: true,
        path: `${ROUTES.CARD_TRANSACTIONS}/:id`,
        component: CardTransactionDetailsPage
      },
      {
        exact: true,
        path: `${ROUTES.CHECK_INS}/:id`,
        component: CheckInDetailsPage
      },
      {
        exact: true,
        path: ROUTES.CORPORATE_PARTNERS,
        component: CorporatePartnersLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.CORPORATE_PARTNERS}/:id`,
        component: CorporatePartnerDetailsPage
      },
      {
        exact: true,
        path: ROUTES.CORPORATE_PARTNERS_CREATE,
        component: CorporatePartnerCreatePage
      },
      {
        exact: true,
        path: ROUTES.CREDIT_BANDS,
        component: CreditBandDetailsPage
      },
      {
        exact: true,
        path: ROUTES.CREDIT_OFFSETS,
        component: CreditOffsetsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.CREDIT_OFFSETS}/:id`,
        component: CreditOffsetDetailsPage
      },
      {
        exact: true,
        path: `${ROUTES.CREDIT_OFFSETS_CREATE}`,
        component: CreditOffsetCreatePage
      },
      {
        exact: true,
        path: `${ROUTES.CREDIT_BANDS}/:id`,
        component: CreditBandDetailsPage
      },
      {
        exact: true,
        path: ROUTES.GIFT_PURCHASES,
        component: GiftPurchasesLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.GIFT_PURCHASES}/:id`,
        component: GiftPurchaseDetailsPage
      },
      {
        exact: true,
        path: `${ROUTES.USERS}/:id`,
        component: UserDetailPage
      },
      {
        exact: true,
        path: `${ROUTES.MOVIES}/:id`,
        component: MovieDetailsPage
      },
      {
        exact: true,
        path: ROUTES.MOVIES,
        component: MoviesLandingPage
      },
      {
        exact: true,
        path: '/carousel',
        component: MoviesCarouselPage
      },
      {
        exact: true,
        path: '/list',
        component: MoviesHomeListPage
      },
      {
        exact: true,
        path: `${ROUTES.QUALIFIERS_UNIFIED_CREATE}`,
        component: UnifiedQualifierCreatePage
      },
      {
        exact: true,
        path: `${ROUTES.QUALIFIERS}`,
        component: QualifiersLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.QUALIFIERS}${ROUTES.UNIFIED}/:id`,
        component: UnifiedQualifierDetailsPage
      },
      {
        exact: true,
        path: `${ROUTES.QUALIFIERS_PROVIDER_CREATE}`,
        component: ProviderQualifierCreatePage
      },
      {
        exact: true,
        path: `${ROUTES.QUALIFIERS}${ROUTES.PROVIDER}/:id`,
        component: ProviderQualifierDetailsPage
      },
      {
        exact: true,
        path: `${ROUTES.RESERVATIONS}/:id`,
        component: ReservationDetailsPage
      },
      {
        exact: true,
        path: ROUTES.SERVICE_FEE_OFFSETS,
        component: ServiceFeeOffsetsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.SERVICE_FEE_OFFSETS}/:id`,
        component: ServiceFeeOffsetDetailsPage
      },
      {
        exact: true,
        path: `${ROUTES.SERVICE_FEE_OFFSETS_CREATE}`,
        component: ServiceFeeOffsetCreatePage
      },
      {
        exact: true,
        path: `${ROUTES.THEATER_CHAINS}/:id`,
        component: TheaterChainDetailsPage
      },
      {
        exact: true,
        path: `${ROUTES.THEATER_CHAIN}`,
        component: TheaterChainAddPage
      },
      {
        exact: true,
        path: `${ROUTES.THEATER_CHAINS}`,
        component: TheaterChainsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.THEATERS_CREATE}`,
        component: TheaterCreatePage
      },
      {
        exact: true,
        path: `${ROUTES.THEATERS}/:id`,
        component: TheaterDetailsPage
      },
      {
        exact: true,
        path: ROUTES.THEATERS,
        component: TheatersLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.TICKET_TYPES}/:id`,
        component: TicketTypeDetailsPage
      },
      {
        exact: true,
        path: ROUTES.TICKET_TYPES,
        component: TicketTypesLandingPage
      },
      {
        exact: true,
        path: ROUTES.POSTAL_CODES,
        component: PostalCodesLandingPage
      },
      {
        exact: true,
        path: ROUTES.NETWORKS,
        component: NetworksLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.NETWORKS}/:id`,
        component: NetworkDetailsPage
      },
      {
        exact: true,
        path: `${ROUTES.POSTAL_CODES}/:id`,
        component: PostalCodeDetailsPage
      },
      {
        exact: true,
        path: ROUTES.PRICES,
        component: PricesLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.PRICES}/:id`,
        component: PriceDetailsPage
      },
      {
        exact: true,
        path: ROUTES.VIP_USERS,
        component: VipUsersLandingPage
      },
      {
        exact: true,
        path: ROUTES.VIP_USERS_CREATE,
        component: VipUserCreatePage
      },
      {
        exact: true,
        path: ROUTES.WAITLIST_USERS,
        component: WaitlistUsersLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.WAITLIST_USERS}/:id`,
        component: WaitlistUserDetailsPage
      },
      {
        exact: true,
        path: ROUTES.WAITLIST_USERS_ADD,
        component: WaitlistUserAddPage
      },
      {
        exact: true,
        path: '/invite',
        component: WaitlistUsersInvitePage
      },
      {
        exact: true,
        path: ROUTES.OFFERS,
        component: OffersLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.OFFERS}/:id`,
        component: OfferDetailsPage
      },
      {
        exact: true,
        path: ROUTES.OFFER_WALLS,
        component: OfferWallsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.OFFER_WALLS}/:id`,
        component: OfferWallDetails
      },
      {
        exact: true,
        path: ROUTES.PRESHOWS,
        component: PreshowsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.PRESHOWS}/:id`,
        component: PreshowDetailPage
      },
      {
        exact: true,
        path: ROUTES.SPOTS,
        component: SpotsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.SPOTS}/:id`,
        component: SpotDetailsPage
      },
      {
        exact: true,
        path: ROUTES.SPOT_ACTIONS,
        component: SpotActionsLandingPage
      },
      {
        exact: true,
        path: ROUTES.SURVEYS,
        component: SurveysLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.SURVEYS}/:id`,
        component: SurveyDetailsPage
      },
      {
        exact: true,
        path: ROUTES.ITEMS,
        component: ItemsLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.ITEMS}/:id`,
        component: ItemDetailsPage
      },
      {
        exact: true,
        path: ROUTES.BRANDS,
        component: BrandsLandingPage
      },
      {
        exact: true,
        path: ROUTES.ADMINISTRATORS,
        component: AdminUsersLandingPage
      },
      {
        exact: true,
        path: `${ROUTES.ADMINISTRATORS}/:id`,
        component: AdminUserDetailPage
      },
      {
        component: () => <Redirect to={ROUTES.NOTFOUND} />
      }
    ]
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardContainer,
    routes: [
      {
        exact: true,
        path: ROUTES.HOME,
        component: () => <Redirect to={ROUTES.USERS} />
      },
      {
        component: () => <Redirect to={ROUTES.NOTFOUND} />
      }
    ]
  }
];

export default routes;
